<template>
  <div class="result-container">
    <table border="0" class="info-table">
      <tr class="table-row">
        <td class="table-key">查询条件：</td>
        <td class="table-value">{{selectMsg}}</td>
      </tr>
      <tr class="table-row">
        <td class="table-key">订单情况：</td>
        <td class="table-value">
          <span style="margin-right: 10px">
            {{ resultList.buyer_count || "0" }}买家/{{
              resultList.total || "0"
            }}订单
          </span>
          <span>
            {{ resultList.current_page || "0" }}/{{
              resultList.last_page || "0"
            }}页
          </span>
        </td>
      </tr>
      <tr class="table-row">
        <td class="table-key">快递模板：</td>
        <td class="table-value">
          <el-radio-group v-model="expressTemplate" @change="handleRadioChange">
            <el-radio
              v-for="(item, index) in expressTemplateList"
              :key="index"
              :label="index + ''"
            >
              {{ item.express_name }}
            </el-radio>
          </el-radio-group>
        </td>
        <!-- <p class="handlBtn" @click="addClick" style="margin-right:10px;padding:1px 2px">+</p>
        <p class="handlBtn" @click="reduceClick" style="margin-right:10px;padding:1px 4px">-</p> -->
      </tr>
    </table>

    <div v-if="resultList.today_num || resultList.overtime_num" style="margin-left: 35px">
     <!-- <div class="messageClass" style='background-color:#E9F3FD;border:none;padding-left: 10px; box-sizing:border-box;margin-top:0px;'>
        <el-tooltip placement="bottom" effect="light" style="margin-right: 15px">
        <span class="help-tip" style='background-color:#32aaf9;color:white !important;'>!</span>
        <div slot="content">已选中行数</div>
      </el-tooltip>
      <span style="color: #c4acad">已选中：{{ selectRowLength }}</span>
     </div> -->
      <div class="messageClass">
        <div>
          发货提醒：您有<span>{{resultList.today_num||"0"}}</span>个待发货订单需要24小时内发出，<span>{{resultList.overtime_num||"0"}}</span>个已经发货超时，超时可能会被判罚，建议您尽快发货！<p @click="infoBtn">查看详情</p>
        </div>
      </div>
    </div>
    <!-- 订单展示表格 -->
    <el-table
      stripe
      v-loading="resultLoading"
      ref="multipleTable"
      :row-key='getRowKeys'
      :expand-row-keys="expandRow"
      :data="resultList.data"
      class="result-table"
      @select="handleSelect"
      @select-all="handleALL"
      @selection-change="handelSelectChange"
      @expand-change="handleExpand"
      :row-class-name="tableRowClassName"
    >
      <el-table-column label="" width='40px'>
        <template slot-scope="scope">
          <i v-if="scope.row.is_hang==1" class="el-icon-lock"></i>
          <div style="margin-left: 5px">
            <span v-if="scope.row.express_print_state=='1'" style="color:#488b00;margin-right:5px">✔</span>
            <span v-if="scope.row.invoice_print_state=='1'" style="color:#3da6e3;margin-right:5px">✔</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="买家昵称"
      >
        <template slot-scope="scope">
          <div>
            <span v-if="!scope.row.buyer_login_id">-</span>
            <span v-else>{{ scope.row.buyer_login_id }}</span>
          </div>
        </template>
      </el-table-column>
      <template v-for="(item, index) in tableHeader">
      <el-table-column :key="index" :label="item.lable" v-if="item.lable !== '产品内容' &&
      item.lable != '买家昵称' &&
      item.lable != '数量' &&
      item.lable != '收件人' &&
      item.lable != '订单编号' &&
      item.lable != '联系电话' &&
      item.lable != '留言备注' &&
      item.lable != '付款时间' &&
      item.lable != '下单时间' &&
      item.lable != '实付金额' &&
      item.lable != '剩余发货时间' &&
      item.lable != '操作'">
        <template slot-scope="scope">{{ scope.row[item.key] }}</template>
      </el-table-column>
      <el-table-column min-width='50' :key="index" :label="item.lable" v-if="item.lable == '数量'">
        <template slot-scope="scope">
          <span :style="scope.row.quantity > 1 ? 'color:red;' : 'color:black;'">{{ scope.row.quantity }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width='50' :key="index" :label="item.lable" v-if="item.lable == '收件人'">
        <template slot-scope="scope">
          <span>{{ scope.row.contactPerson }}</span>
        </template>
      </el-table-column>
      <el-table-column :key="index" :label="item.lable" v-if="item.lable == '订单编号'"> <template
          slot-scope="scope">
          <span>{{ scope.row.tid_list ? scope.row.tid_list : scope.row.transaction_id }}</span>
        </template>
      </el-table-column>
       <el-table-column :key="index" :label="item.lable" v-if="item.lable == '联系电话'"> <template
          slot-scope="scope">
          <span>{{scope.row.mobile }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width='100' :key="index" :label="item.lable" v-if="item.lable == '留言备注'">
        <template slot-scope="scope">
          <div>
            <p :title="scope.row.buyer_feedback">
              留言：{{ scope.row.buyer_feedback }}</p>
            <p :title="scope.row.seller_memo">
              备注：{{ scope.row.seller_memo }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width='150' :key="index" :label="item.lable" v-if="item.lable == '产品内容'">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: column;">
            <div class="product-info" v-for="(item, index) in scope.row.order_sub" :key="index">
                <el-image style="min-width: 30px; height: 30px; margin: 0 5px" :src="item.product_img_url" fit="fill">
              </el-image>
              <div>
                <p>
                  {{ item.name }}
                  <template v-if="item.sku_info">: {{item.sku_info}}</template>
                  <!-- <template v-for="(sku_item, sku_index) in item.sku_info">{{ sku_item.name }}({{ sku_item.value }}){{
                    sku_index != 0 ? "," : ""
                    }}</template> -->
                </p>
                <p style="color: red; font-weight: 1000">
                  {{ item.quantity }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :key="index" :label="item.lable" v-if="item.lable == '付款时间'"> <template
          slot-scope="scope">
          <span>{{ scope.row.pay_time }}</span>
        </template>
      </el-table-column>
      <el-table-column :key="index" :label="item.lable" v-if="item.lable == '下单时间'"> <template
          slot-scope="scope">
          <span >{{ scope.row.create_time }}</span>
        </template>
      </el-table-column>
      <el-table-column :key="index" :label="item.lable" v-if="item.lable == '实付金额'"> <template
          slot-scope="scope">
          <span>{{ scope.row.item_amount }}</span>
        </template>
      </el-table-column>
      <el-table-column :key="index" :label="item.lable" v-if="item.lable == '剩余发货时间'"> <template
          slot-scope="scope">
          <span :style="scope.row.delivery_limit == '已超时' ? 'color:red;' : 'color:black;'">{{ scope.row.delivery_limit
            }}</span>
        </template>
      </el-table-column>
    </template>
      <el-table-column type="expand" width='80px'>
        <template slot="header" slot-scope="scope">
          <span style="margin-right: 25px">操作</span>
          <i class="iconfont icon-down"></i>
        </template>
       <template slot-scope="props">
        <div class="expend-container" @mouseleave="offShow">
          <div class="row1">
            <table border="0" class="expend-table">
              <tr class="table-row">
                <td class="table-key">昵称：</td>
                <td class="table-value">
                  {{ props.row.buyer_login_id | nullToChinese }}
                </td>
                <td class="table-menu">
                  <el-button size="mini" v-clipboard:copy="buyerId"      v-clipboard:success="onCopy"
                  v-clipboard:error="onError">
                    复制昵称
                  </el-button>
                  <!-- <el-button size="mini" v-clipboard:copy="props.row.buyer_login_id"      v-clipboard:success="onCopy"
                  v-clipboard:error="onError">
                    复制昵称
                  </el-button> -->

                  <el-button size="mini" v-clipboard:copy="copyValue" v-clipboard:success="onCopy"
                    v-clipboard:error="onError">
                    复制全部
                  </el-button>
                  <el-button size="mini" v-clipboard:copy='copyValueAdress' v-clipboard:success="onCopy"
                    v-clipboard:error="onError">
                    复制地址
                  </el-button>

                </td>
                <!-- <td class="table-key">
                   <el-image
                    style="width: 20px; height: 20px"
                    :src="showAll ? OSS+('/show.svg') : OSS+('/hidden.svg')"
                    @click ="showInfo(showAll,props.$index)"
                    ></el-image>
                </td> -->
              </tr>
              <tr class="table-row">
                <td class="table-key">收件人：</td>
                <td class="table-value">
                  {{ (showAll ? decryptInfo.contactPerson : props.row.contactPerson) | nullToChinese }}
                </td>
                <td class="table-key">手机：</td>
                <td class="table-value">
                  {{ (showAll ? decryptInfo.mobile : props.row.mobile) | nullToChinese }}
                  <!-- {{ (showAll ? decryptInfo.mobile : props.row.to_mobile) | nullToChinese }} -->
                </td>
                <!-- <td class="table-key">固话：</td>
                <td class="table-value">
                  {{ props.row.to_phone | nullToChinese }}
                </td> -->
                <td class="table-key">邮编：</td>
                <td class="table-value">
                  {{ props.row.to_division_code | nullToChinese }}
                </td>
              </tr>
              <tr class="table-row">
                <td class="table-key">收件地址：</td>
                <td class="table-value" v-if="addressEdit[props.$index] == false">
                  <span>
                    {{
                    props.row.province +
                    " " +
                    props.row.city +
                    " " +
                    props.row.area +
                    " " +
                    props.row.town +
                    " " +
                    (showAll ? decryptInfo.address : props.row.address)
                    }}
                  </span>
                  <!-- <el-button v-if="props.row.showUpAdres == 0" type="text"
                    @click="showAddressSelect(props.$index, props.row)">
                    (更改)
                  </el-button> -->
                   <el-button type="text" @click="showAddressSelect(props.$index, props.row)">
                    (更改)
                  </el-button>
                </td>
                <td class="table-menu" v-if="addressEdit[props.$index] == true">
                  <el-form :inline="true" style="display: inline-block; margin-right: 20px">
                    <el-form-item>
                      <el-select v-model="props.row.province" placeholder="请选择省份" size="mini"
                        @change="provinceChanged($event, props.$index)">
                        <el-option v-for="(item, code) in provinces" :key="code" :label="item" :value="code">
                        </el-option>
                      </el-select>
                      <el-select v-model="props.row.city" :loading="loadingCity"
                        @change="cityChanged($event, props.$index)" @visible-change="cityLoad(props.$index)" size="mini"
                        placeholder="请选择城市" style="margin: auto 10px">
                        <el-option v-for="(item, code) in cities" :key="code" :label="item" :value="code">
                        </el-option>
                      </el-select>

                      <el-select v-model="props.row.area" :loading="loadingDistrict"
                        @change="districtChanged($event, props.$index)" @visible-change="districtLoad(props.$index)"
                        size="mini" placeholder="请选择地区">
                        <el-option v-for="(item, code) in districts" :key="code" :label="item" :value="code">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="props.row.town" placeholder="请输入街道" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="props.row.address" placeholder="请输入详细地址" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button size="mini" @click="updateAddress(props.$index)">确定</el-button>
                      <el-button type="warning" size="mini" @click="hideAddressSelect(props.$index, props.row)">取消
                      </el-button>
                      <span style="font-size: 12px">
                        （不会改变阿里订单地址）
                      </span>
                    </el-form-item>
                  </el-form>
                </td>
              </tr>
            </table>
          </div>
          <div>
            <div class="row2">
              <span style="margin-right: 30px">
                {{ props.row.transaction_id | nullToChinese }}
              </span>
              <span style="color: red; font-weight: 1000; margin-right: 30px">
                {{props.row.statusStr}}</span>
              <span style="margin-right: 30px">{{
                props.row.create_time | nullToChinese
                }}</span>
              <span style="color: #735502; font-weight: 1000; margin-right: 30px">订单金额{{ props.row.sum_product_payment
                | nullToNum
                }}元</span>
              <span style="color: #735502; font-weight: 1000; margin-right: 30px">实付{{ props.row.item_amount |
                nullToNum
                }}元 （邮费:{{
                props.row.shipping_fee | nullToNum
                }}元）</span>
            </div>
            <div class="row3" style="position: relative;">
              <table border="0" class="expend-table">
                <tr style="display:flex">
                  <td class="table-key" style="white-space:nowrap">留言：</td>
                  <td class="table-value">
                    {{ props.row.buyer_feedback | nullToChinese }}
                  </td>
                </tr>
                <tr>
                  <td class="table-key" style="white-space:nowrap">备注：</td>
                  <td class="table-value">
                    {{ props.row.seller_memo | nullToChinese }}
                    <div style="display: inline-block;width: 14px;height: 14px;margin-right: 20px;">
                      <img v-if="flagImgShow" class="flagClass" style="margin-left:10px" :src="props.row.flagValue"
                        alt="">
                    </div>
                    <el-button type="text" style="display:inline-block" @click="changeMemo(props.row.seller_memo)">
                      (更改)</el-button>
                  </td>
                </tr>
                <div class="table-value flagDiv" v-if="flagShow">
                  <el-radio v-model="falgRadio_temp" @change='changeFlag("1")' label="1"><img class="flagClass"
                      style="cursor: pointer;" :src="OSS + '/redFlag.png'" alt=""></el-radio>
                  <el-radio v-model="falgRadio_temp" @change='changeFlag("2")' label="2"><img class="flagClass"
                      style="cursor: pointer;" :src="OSS + '/blueFlag.png'" alt=""></el-radio>
                  <el-radio v-model="falgRadio_temp" @change='changeFlag("3")' label="3"><img class="flagClass"
                      style="cursor: pointer;" :src="OSS + '/greenFlag.png'" alt=""></el-radio>
                  <el-radio v-model="falgRadio_temp" @change='changeFlag("4")' label="4"><img class="flagClass"
                      style="cursor: pointer;" :src="OSS + '/yellowFlag.png'" alt=""></el-radio>
                  <br>
                  <el-input v-model="seller_memo_value" placeholder="无" size="mini" style="border:none;margin-top:15px">
                    {{ seller_memo_value }}</el-input>
                  <input class="handleBtn" type="button" value="保存" @click="saveMemo(props.row, props.$index)">
                  <input class="handleBtn" type="button" value="返回" @click="flagShow = false">
                </div>
                <tr class="table-row" :style="{ marginTop: flagShow ? '100px' : '', display: flagShow ? 'block' : '' }">
                  <td class="table-key" style="height: inherit; vertical-align: top">
                    商品：
                  </td>
                  <td style="height: inherit;display:inline-block">
                    <!-- <el-checkbox-group v-model="props.row.checkedGoods" @change="handleCheckedGoodsChange(props.$index,props.row.checkedGoods)"> -->
                    <div style="display: flex;align-items: center;margin-bottom: 8px;align-items: flex-start;" v-for="(item, index) in props.row.order_sub" :label="item" :key="index">
                      <!-- <el-image class="row3-img" :src="item.product_img_url[0]" fit="fill"></el-image> -->
                      <el-image class="row3-img" :src="item.product_img_url" fit="fill"></el-image>
                      <div style="display: inline-block; color: #9b9b9b">
                        <p v-if="item.name != ''" style="padding: 0 0 2px 0">
                          {{ item.name }}
                        </p>
                        <div style="display: flex; align-items: center">
                          <span style="
                              color: black;
                              padding: 3px 0;
                              font-weight: 1000;
                              margin-right: 15px;
                            ">规格：
                            <template>{{ item.sku_info }}</template>
                            <!-- <template v-for="(sku_item, sku_index) in item.sku_info">{{ sku_item.name }}({{
                              sku_item.value
                              }}){{ sku_index != 0 ? "," : "" }}</template> -->
                          </span>
                          <span style="
                              color: black;
                              padding: 3px 0;
                              font-weight: 1000;
                              margin-left: 10px;
                            ">商品数量：{{ item.quantity }}</span>
                        </div>
                        <p style="padding: 3px 0">
                          <span style="margin-right: 15px">定价：{{ item.price }}</span>
                          <span>实付金额：{{ item.item_amount }}</span>
                          <span style="
                              margin-left: 15px;
                              color: red;
                              font-weight: 1000;
                            " v-if="item.statusStr">({{ item.statusStr | nullToChinese }})</span>
                          <span style="
                              margin-left: 5px;
                              color: #0023ff;
                              font-weight: 1000;
                            " v-if="item.refundStatusStr">({{ item.refundStatusStr | nullToChinese }})</span>
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
         
        </div>
      </template>
      </el-table-column>
    </el-table>
    <div class="pagination" >
      <el-pagination
        :current-page.sync="resultList.current_page"
        background
        layout="prev, pager, next"
        :page-size="resultList.per_page"
        :total="resultList.total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <el-dropdown trigger="click" @command="handleSizeChange" class="dropdownClass">
        <span class="el-dropdown-link">
          {{pageDropdown}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for='(item, index) in pageArr' :command="item">{{item == '全部' ?item+'/页' :item+'条/页'}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div style="width: 1000px;margin: 40px auto 100px;">
      <el-divider></el-divider>
      <div style="padding: 10px;display: flex;justify-content: center; flex-direction: column-reverse; align-items: center;">
        <span
          >(<span style="color: red">谷歌浏览器</span
          >可能会有打印文字竖排显现，如您碰到请切换其他浏览器)</span
        >
        <!-- <br /> -->
        <!-- 最底层图标说明 -->
        <span style="color: #B5B5B5;margin-left: 20px;">
          <span>图标说明：</span>
          <!-- <i class="iconfont el-icon-check" style="font-size: 8px; color: #15B415;"></i> -->
          <span style="font-size: 8px; color: #08a908;font-weight: 600">✓</span>
          <span>已打印快递单</span>
          <!-- <i class="iconfont iel-icon-check" style="font-size: 8px; color: #15B415;"></i> -->
          <span style="font-size: 8px; color: #38a4e2;font-weight: 600">✓</span>
          <span>已打印发货单</span>
          <!-- <i class="iconfont icon-yes" style="font-size: 8px; color: #15B415;"></i> -->
          <!-- <span style="font-size: 8px; color: #08a908;font-weight: 600">//</span>
          <span>部分订单已打印快递单</span> -->
          <!-- <i class="iconfont icon-yes" style="font-size: 8px; color: #15B415;"></i> -->
          <!-- <span style="font-size: 8px; color: #38a4e2;font-weight: 600">//</span>
          <span>部分订单已打印发货单，以当前快递单号为基础向下生成单号</span> -->
          <!-- <i class="iconfont icon-yes" style="font-size: 8px; color: #15B415;"></i> -->
          <!-- <span style="font-size: 8px; color: #08a908;font-weight: 600">✓</span>
          <span>当前选择的快递公司可以到</span> -->
        </span>
        <!-- <el-button type="text" @click="tipsDialogShow = true">(提示)</el-button> -->
      </div>
    </div>
    <!-- 底部按钮和文字信息 -->
    <div class="footer-container">
      <div class="btn-container">
        <div class="icon-box">
          <el-tooltip placement="bottom" effect="light" style="margin-right: 15px">
            <span class="help-tip">!</span>
            <div slot="content">已选中行数</div>
          </el-tooltip>
          <span style="color: #c4acad">已选中：{{ selectRowLength }}</span>
        </div>
        <div style="padding-right: 10px;">
          <el-button class="footer-btn btn1" @click="handleClickFahuoPrint"
            >打印发货单</el-button
          >
          <el-button class="footer-btn btn2" @click="handleClickExpressPrint"
            >打印快递单</el-button
          >
          <el-button class="footer-btn btn3"  @click="handleClickDeliverGoods"
            >发 货</el-button>
          <el-dropdown placement='top' @command='chenckItemHandle' @visible-change='visibleChange'>
            <el-button class="footer-btn btn4" >更多操作<i class="el-icon-arrow-down el-icon--right" :style="{transform: visibleChangeValue ?'rotate(180deg)' :''}"></i></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command='挂起'>挂起</el-dropdown-item>
              <el-dropdown-item command='取消挂起'>取消挂起</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- 图标说明的提示 -->
    <el-dialog
      :visible.sync="tipsDialogShow"
      width="35%"
      background-color='#fff'
      center>
      <div class="tipsContentClass">
        <i class="el-icon-warning"></i>
        <span>
          快递判断数据由快递公司提供，根据使用经验准确率在95%左右，其中乡镇地址和开发区/新区地址请在快递网站上核对
        </span>
    
      </div>
    </el-dialog>
    <!-- 订单备注弹窗 -->
    <my-dialog-1
      :dialogTitle="'修改备注'"
      :centerDialogVisible="dialogRemarkVisible"
      :popupWidth="'480px'"
      @updateVisible="updateRemarkVisible"
      @resetPopupData="hideRemarkDialog"
      @submitPopupData="saveRemarkDialog"
    >
    </my-dialog-1>
    <!-- 发货单设置发件人弹窗 -->
    <ShipFromDialog :selectRowLength='this.selectRowLength' :orderInfoList='this.orderInfoList' :type='1'/>
    <!-- 快递单设置发件人弹窗 -->
    <CourierSender :selectRowLength='this.selectRowLength' :orderInfoList='this.orderInfoList' :has_express_print_state='this.has_express_print_state' :type='1'  />
    
    <el-dialog
        :visible.sync="dialogShow"
        width="23%"
        background-color='#fff'
        center>
        <div class="contentClass">
          <i class="el-icon-success"></i>
          <span>{{dialogText2}}</span>
        </div>
    </el-dialog>
    <el-dialog
        :visible.sync="dialogShow9"
        width="23%"
        background-color='#fff'
        center>
        <div class="contentClass">
          <i class="el-icon-success"></i>
          <span>所选订单挂起成功！</span>
        </div>
    </el-dialog>
    <el-dialog
        :visible.sync="dialog2Show"
        width="23%"
        background-color='#fff'
        :show-close = 'false'
        center>
        <div class="contentClass contentClass2">
          <div>
            <i class="el-icon-warning"></i>
            <span>{{dialogText}}</span>
          </div>
          <div class="contentFooter">
            <p @click="dialog2Show=false">确定</p>
          </div>
        </div>
    </el-dialog>
    <!-- <el-dialog
        :visible.sync="dialog3Show"
        width="23%"
        background-color='#fff'
        :show-close = 'false'
        center>
        <div class="contentClass3">
          <div>
            <i class="el-icon-warning"></i>
            <span>您未安装最新的打印控件</span>
            <p style="margin-top:15px">安装完成请<span class="refreshBtn" @click="refreshBtn">刷新页面</span>或重启浏览器</p>
          </div>
          <div class="contentFooter">
            <div @click="comfirBtn">立即下载</div>
            <div @click="dialog3Show = false">取消</div>
          </div>
        </div>
    </el-dialog> -->

    <el-dialog
        :visible.sync="dialogShow7"
        width="35%"
        background-color='#fff'
        center>
        <div class="contentClass7">
          <div>
            <i class="el-icon-warning"></i>
            <span>{{messageText}}</span>
          </div>
        </div>
    </el-dialog>
	<!-- nameVlaue -->
    <el-dialog
        :visible.sync="dialogShow8"
        width="35%"
        background-color='#fff'
        center>
        <div class="contentClass8">
          <div>
            <i class="el-icon-warning"></i>
            <span>【{{nameVlaue}}】未勾选要打印的宝贝</span>
            <p style="margin-top:5px;width:100%">
              请展开列表右侧详情，勾选宝贝后再试
            </p>
            <img :src="OSS+'/dialogImg.png'" alt="">
          </div>
          <div class="contentFooter">
            <div @click="dialogShow8 = false">确定</div>
          </div>
        </div>
    </el-dialog>

      <el-dialog :visible.sync="dialogShow12" width="32%" background-color='#fff' center>
    <div class="contentClass12">
      <p class="topBg"></p>
      <div class="topTitle">
        <span>修改备注</span>
      </div>
      <div style="padding:15px">
        <el-input type="textarea" :rows="6" placeholder="（此处填写的备注内容将会覆盖订单原备注）" resize='none'
          v-model="NaNpxarksTextarea">
        </el-input>
      </div>
      <div class="flagClass">
        <div :class="flagIndex == 1 ? 'flageActive' : ''" @click="flagIndex = 1">
          <img :src="OSS + '/redFlag.png'" alt="">红旗
        </div>
        <div :class="flagIndex == 2 ? 'flageActive' : ''" @click="flagIndex = 2">
          <img :src="OSS + '/blueFlag.png'" alt="">蓝旗
        </div>
        <div :class="flagIndex == 3 ? 'flageActive' : ''" @click="flagIndex = 3">
          <img :src="OSS + '/greenFlag.png'" alt="">绿旗
        </div>
        <div :class="flagIndex == 4 ? 'flageActive' : ''" @click="flagIndex = 4">
          <img :src="OSS + '/yellowFlag.png'" alt="">黄旗
        </div>
        <!-- <div :class="flagIndex==1 ?'flageActive' :''" @click="flagIndex=1">
          <img :src="OSS+'/purpleFlag.png'" alt="">紫旗
        </div> -->
      </div>
      <div class="footerClass">
        <div @click="saveRemarkValue">保存修改</div>
        <div @click="dialogShow12 = false">取消</div>
      </div>
    </div>
  </el-dialog>

    <!-- 已打印弹窗 -->
     <reprintDialog1 v-if="dialogShow10" @ok="()=>{this.$store.commit('batchprint/SAVE', {dialogFahuoConsignerVisible:true});this.dialogShow10=false}" @cancel="()=>{this.dialogShow10=false}" />
   
    <reprintDialog3 v-if="dialogShow13" :type="is_hang_dialog_type" @cancel="()=>{this.dialogShow13=false}" />
    <GetFahuoErrorPopup v-if="GetFahuoError" :dataSrc="GetFahuoError" @closePopup="()=>{this.GetFahuoError=false}"></GetFahuoErrorPopup>

    <appDialog @closePopup="()=>{this.print_component_error=false}"  v-if="print_component_error" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getPrinterList } from "@/utils/lodopPrint";
import provinceCity from "@/static/locationOption.json";
import myDialog1 from "@/UI/myDialog1";
import myDialog3 from "@/UI/myDialog3";
import reprintDialog1 from "@/components/dialog/reprintDialog1";

import reprintDialog3 from "@/components/dialog/reprintDialog3";
import { OSS } from "@/utils/PublicLink";
const redFlag = OSS +'/redFlag.png';
const yellowFlag = OSS + "/yellowFlag.png";
const greenFlag = OSS + "/greenFlag.png";
const blueFlag = OSS + "/blueFlag.png";


import GetFahuoErrorPopup from "@/UI/GetFahuoErrorPopup";
import appDialog from "@/components/appDialog";

import ShipFromDialog from "@/components/selectDialog/ShipFromDialog";
import CourierSender from "@/components/selectDialog/CourierSender";

export default {
  name: "ResultList",
  components: {
    myDialog1,
    myDialog3,
    reprintDialog1,

    reprintDialog3,
    GetFahuoErrorPopup,
    appDialog,

    ShipFromDialog,//发货单设置弹窗
    CourierSender,//请选择快递发件人弹窗
  },
  data() {
    return {
      // 打印机列表
      printerList: this.PRINTERLIST || window.ks_print_list,
      // 被选中的订单信息
      orderInfoList: [],
      orderOidList: "",
      falgRadio_temp: "",
      // resulttable变量
      // 被选中的行
      selectRow: [],
      // 被选中的行数
      selectRowLength: 0,
      // 地址输入框控制参数
      addressEdit: [],
      loadingCity: false,
      loadingDistrict: false,
      provinces: [],
      cities: [],
      districts: [],
      // 被展开的行
      expandRow: [],
      // 备注弹窗变量
      dialogRemarkVisible: false,
      // 发货单发货人设置弹窗变量
      dialogFahuoConsignerVisible: false,
      // 快递单发货人设置弹窗变量
      dialogExpressConsignerVisible: false,
      expressConsigner: {},
      // 快递打印确认弹窗变量
      // dialogExpressVisible: false,
      // // 快递打印确认弹窗绑定数据
      // expressPrintOption: {
      //   printer: "",
      //   num: "1",
      // },
      showAll:false,
      pageDropdown: '50条/页',
      pageArr: ['50','100','200','500'],
      dialogShow: false, // 复制成功的弹窗
      dialog2Show: false, // 未勾选点击打印发货单
      dialog3Show: false, // 未安装打印控件
      checkedGoods: [],
      getRowKeys(row) {
        return row.transaction_id
      },
      addressee: '', // 有退款时的收件人姓名
      dialogShow6: false, // 插件未连接或某种情况不好用
      dialogShow7: false, // 其他打印不了的问题
      dialogShow8: false, // 未勾选商品
      dialogShow9: false, // 挂起成功的弹窗
      dialogShow10: false, // 判断是否有打印过的发货单
      dialogShow12: false, // 批量修改备注
      dialogShow13: false, // 提示挂起弹窗
      is_hang_dialog_type: '', // 提示挂起弹窗类型
      tipsDialogShow: false, // 点击提示的弹窗
      messageText: '模板网点地址信息获取错误：快手默认地址未设置',
      nameVlaue: '', 
      dialogText: '请勾选打印对象',
      dialogText2: '',
      visibleChangeValue: false, // 下拉框的打开状态
      seller_memo_value: '',
      GetFahuoError: false, // 发货错误
      print_component_error: false,
      selectMsg:'',
      showAdress:'',
      has_express_print_state:false,

      // 
      flagShow: false,
      flagImgShow: true,
      flagIndex: -1,
      memoParams: {}, // 批量修改备注的传参
      NaNpxarksTextarea: '',
      tableHeader:[
        {lable:"购买数量",key:"goods_count",is_selected:1,is_sort:1,tips:""},
        {lable:"收件人",key:"to_full_name",is_selected:1,is_sort:1,tips:""},
        {lable:"订单编号",key:"transaction_id",is_selected:1,is_sort:1,tips:""},
        {lable:"联系电话",key:"to_mobile",is_selected:1,is_sort:1,tips:""},
        {lable:"收货地址",key:"to_area",is_selected:1,is_sort:1,tips:""},
        {lable:"快递单号",key:"logistics_bill_no",is_selected:1,is_sort:1,tips:""},
        {lable:"发件人",key:"consignor",is_selected:1,is_sort:1,tips:""},
        {lable:"留言备注",key:"seller_memo",is_selected:1,is_sort:1,tips:""},
        {lable:"产品内容",key:"order_sub",is_selected:1,is_sort:1,tips:""},
        {lable:"付款时间","key":"pay_time","show":false,is_selected:1,is_sort:1,tips:""},
        {lable:"下单时间",key:"create_time",is_selected:1,is_sort:1,tips:""},
        {lable:"实付金额",key:"item_amount",is_selected:1,is_sort:1,tips:""},
        {lable:"剩余发货时间",key:"delivery_limit",is_selected:1,is_sort:1,tips:"根据发货时效和付款时间计算后的倒计时。"},
        {lable:"操作",key:"","disabled":true,is_selected:0,is_sort:0,tips:""}]
   };
  },
  computed: {
    ...mapState({
      pageHide: (state) => state.batchprint.pageHide,
      expressList: (state) => state.print.expressList,
      expressTemplateList: (state) => state.print.expressTemplateList,
      searchFormData: (state) => state.batchprint.searchFormData,
      resultLoading: (state) => state.batchprint.resultLoading,
      decryptInfo:(state)=>state.singleprint.decryptInfo,
      fahuoConsignerList: (state) => state.print.fahuoConsignerList,
      // tableHeader: (state) => state.print.tableHeader,
      tableHeader2: (state) => state.print.tableHeader2,
      // 打印参数
      printExpressTemplate: (state) => state.batchprint.printExpressTemplate,
      printExpressBgimg: (state) => state.batchprint.printExpressBgimg,
      printFahuoTemplate: (state) => state.batchprint.printFahuoTemplate,
      printFahuoBgimg: (state) => state.batchprint.printFahuoBgimg,
    }),
    
    expressTemplate: {
      get() {
        return this.$store.state.batchprint.expressTemplate;
      },
      set(val) {
        this.$store.commit("batchprint/SET_EXPRESS_TEMPLATE", val);
      },
    },
    resultList: {
      get() {
        this.expandRow = []
        return this.$store.state.batchprint.resultList;
      },
    },
    decryptInfo:{
      get(){
          return this.$store.state.singleprint.decryptInfo;
      },
      set(val){
        // 解密数据置空
        return val;
      }
    }
  },
  created() {
    let image_url = this.expressTemplateList[this.expressTemplate].image_url;
    let lodop_template = this.expressTemplateList[this.expressTemplate].lodop_template;
    this.$store.commit("batchprint/SET_PRINT_EXPRESS_TEMPLATE", lodop_template);
    this.$store.commit("batchprint/SET_PRINT_EXPRESS_BGIMG", image_url);
    this.provinces = provinceCity[0];
    for (var i = 0; i < 20; i++) {
      this.addressEdit[i] = false;
    }
    let data={}
    if(this.resultList.data){
      data['orderId']=this.resultList.data[0].transaction_id;
      data['caid']=this.resultList.data[0].caid;
    }
	  },  
	methods: {
    // 复制成功时的回调函数
    onCopy(e) {
      this.dialogText2 = '成功复制到剪切板！'
      this.dialogShow = true
      setTimeout(() => {
        this.dialogShow = false
      }, 3000)

    },
    // 复制失败时的回调函数
    onError(e) {
      this.$message.error("复制失败！");
    },

    handleRadioChange(lable) {
      let image_url = this.expressTemplateList[lable].image_url;
      let lodop_template = this.expressTemplateList[lable].lodop_template;
      this.expressTemplate = lable
      this.$store.commit(
        "batchprint/SET_PRINT_EXPRESS_TEMPLATE",
        lodop_template
      );
      this.$store.commit("batchprint/SET_PRINT_EXPRESS_BGIMG", image_url);
    },

    // resulttable函数
    // 处理全选表格
    handleALL(val) {
      val = val.filter((item, index) => {
        if(item.is_hang==1){
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(item, false);
          });
          return false
        }else{
          return true
        }
      })
      this.handleSelect(val);
    },
    // 处理多个单选表格
    handleSelect(row) {
      this.selectRow = [];
      this.orderInfoList = [];
      if (row.length > 0) {
        row.forEach((value, index) => {
          // this.selectRow.push(value.oid);
          this.selectRow.push(value.transaction_id);
          this.orderInfoList.push(value);
        });
      }
      this.selectRowLength = row.length;
    },
    // 当选择项发生变化时触发
    handelSelectChange(val) {
      this.handleSelect(val);
    },
    create_copy_data(row) {
      // buyer_login_id
      this.buyerId=row.buyer_login_id?row.buyer_login_id:'无'
      this.copyValue = `
        昵称：${row.buyer_login_id || ""}
        收件人：${row.contactPerson || ""}
        手机：${row.mobile || ""}
        邮编：${row.to_division_code || ""}
        收件地址：${row.province +
        " " +
        row.city +
        " " +
        row.area +
        " " +
        row.town +
        " " +
        row.address
        }
      `,
        this.copyValueAdress = row.province + " " + row.city + " " + row.area + " " + row.town + " " + row.address
    },
    // 处理表格某一行展开
    handleExpand(row, expandedRows) {
      if (row.merge_order) {
        for (let j in row.merge_order) {
          row.merge_order[j].editRemarks = false
          row.merge_order[j].falgRadio_temp = ''
          row.merge_order[j].seller_memo_value = ''
        }
      }
      this.flagShow = false
      this.create_copy_data(row)

      if (expandedRows.length) {
        this.expandRow = []
        if (row) {
          this.expandRow.push(row.transaction_id)// 每次push进去的是每行的ID
        }
      } else {
        this.expandRow = []
      }

      for (let i in this.resultList.data) {
        let data = this.resultList.data[i]
        this.resultList.data[i].falgRadio = data.seller_remark_icon
        this.resultList.data[i].seller_memo_value = data.seller_memo_value
        // if (this.resultList.data[i].is_merge_order == 1) {
        //   for (let k in this.resultList.data[i].merge_order) {
        //     switch (this.resultList.data[i].merge_order[k].seller_remark_icon) {
        //       case '1':
        //         this.resultList.data[i].merge_order[k].flagValue = redFlag
        //         break;
        //       case '2':
        //         this.resultList.data[i].merge_order[k].flagValue = blueFlag
        //         break;
        //       case '3':
        //         this.resultList.data[i].merge_order[k].flagValue = greenFlag
        //         break;
        //       case '4':
        //         this.resultList.data[i].merge_order[k].flagValue = yellowFlag
        //         break;
        //     }
        //   }
        // }
        switch (data.seller_remark_icon) {
          case '1':
            this.resultList.data[i].flagValue = redFlag
            break;
          case '2':
            this.resultList.data[i].flagValue = blueFlag
            break;
          case '3':
            this.resultList.data[i].flagValue = greenFlag
            break;
          case '4':
            this.resultList.data[i].flagValue = yellowFlag
            break;
        }
      }
    },
    // 给表格某一行绑定类名
    tableRowClassName({ row, rowIndex }) {
      let className = "";
      if(row.is_hang==1) {
        className = 'lockClass'
      }
      for (let item of this.selectRow.values()) {
        if (item == row.transaction_id) {
          className = "selected-bgcolor";
        }
      }
      for (let item of this.expandRow.values()) {
        if (item == row.transaction_id) {
          className = "expanded-bgcolor";
        }
      }
      return className;
    },
    provinceChanged(code, index) {
      if (code != "") {
        var str = "0," + code;
        this.loadingCity = true;
        this.cities = provinceCity[str];
        this.resultList.data[index].province_code = code;
        this.resultList.data[index].province = provinceCity[0][code];
        this.loadingCity = false;
        this.nullcity(index);
        this.nulldistrict(index);
      }
    },
    cityChanged(code, index) {
      if (code != "") {
        var str = "0," + this.resultList.data[index].province_code + "," + code;
        this.loadingDistrict = true;
        this.districts = provinceCity[str];
        this.resultList.data[index].city_code = code;
        this.resultList.data[index].city = this.cities[code];
        this.loadingDistrict = false;
        this.nulldistrict(index);
      }
    },
    cityLoad(index) {
      if (this.resultList.data[index].province_code == "") {
        Object.keys(this.provinces).forEach((key) => {
          if (this.provinces[key] == this.resultList.data[index].province) {
            this.resultList.data[index].province_code = key;
          }
        });
      }
      var str = "0," + this.resultList.data[index].province_code;
      this.cities = provinceCity[str];
    },
    districtChanged(code, index) {
      if (code != "") {
        var str =
          "0," +
          this.resultList.data[index].province_code +
          "," +
          this.resultList.data[index].city_code;
        this.resultList.data[index].area = provinceCity[str][code];
        this.resultList.data[index].area_code = code;
        // this.resultList.post_code=code;
      }
    },
    districtLoad(index) {
      if (this.resultList.data[index].city_code == "") {
        Object.keys(this.provinces).forEach((key) => {
          if (this.provinces[key] == this.resultList.data[index].province) {
            var str = "0," + key;
            this.cities = provinceCity[str];
            Object.keys(this.cities).forEach((key2) => {
              if (this.cities[key2] == this.resultList.data[index].city) {
                this.resultList.data[index].city_code = key2;
              }
            });
          }
        });
      }
      var str =
        "0," +
        this.resultList.data[index].province_code +
        "," +
        this.resultList.data[index].city_code;
      this.districts = provinceCity[str];
    },
    nullcity(index) {
      this.resultList.data[index].city_code = "";
      this.resultList.data[index].city = "";
    },
    nulldistrict(index) {
      this.resultList.data[index].area = "";
      this.resultList.data[index].area_code = "";
    },
    updateAddress(index) {
      let params = {
        transaction_id: this.resultList.data[index].transaction_id,
        province: this.resultList.data[index].province,
        city: this.resultList.data[index].city,
        area: this.resultList.data[index].area,
        areaCode: this.resultList.data[index].area_code,
        town: this.resultList.data[index].town,
        address: this.resultList.data[index].address,
        create_time: this.resultList.data[index].create_time,
      }
      this.$store
        .dispatch("print/update_order_address", params)
        .then((res) => {
          if (res.code == 0) {
            this.create_copy_data(this.resultList.data[index])
            this.$message.success(res.message);
            this.$set(this.addressEdit, index, false);
            this.resultList.data[index].to_area = params.province + params.city + params.area + params.town + params.address;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    hideAddressSelect(index, val) {
      val.province = this.addressData.province
      val.city = this.addressData.city
      val.area = this.addressData.area
      val.address = this.addressData.address
      val.town = this.addressData.town
      this.resultList.data[index].province_code = this.addressData.province_code
      this.resultList.data[index].city_code = this.addressData.city_code
      this.resultList.data[index].area_code = this.addressData.area_code
      this.$set(this.addressEdit, index, false);
    },
    showAddressSelect(index, val) {
      this.showAdress = index
      this.addressData = {
        province: val.province,
        city: val.city,
        area: val.area,
        address: val.address,
        town: val.town,
        province_code: this.resultList.data[index].province_code,
        city_code: this.resultList.data[index].city_code,
        area_code: this.resultList.data[index].area_code,
      }
      this.$set(this.addressEdit, index, true);
    },
    // RemarkDialog函数
    // 点击加号按钮显示弹窗
    showRemarkDialog(oid, remark) {
      this.$store.commit("batchprint/SET_REMARK_ORDERID", oid);
      this.$store.commit("batchprint/SET_REMARK_MEMO", remark);
      this.dialogRemarkVisible = true;
    },
    // 更新弹窗是否隐藏函数
    updateRemarkVisible(val) {
      this.dialogRemarkVisible = val;
    },
    // 点击取消按钮隐藏弹窗
    hideRemarkDialog() {
      this.dialogRemarkVisible = false;
    },
    // 点击保存按钮保存数据隐藏弹窗
    saveRemarkDialog() {
      this.$store.dispatch("batchprint/add_order_remark");
      this.dialogRemarkVisible = false;
    },
    // 点击打印发货单按钮
    async handleClickFahuoPrint() {
      try {
        let lodop_printer = await getPrinterList();
        // 挂起订单提示
        for(let i in this.orderInfoList){
          if(this.orderInfoList[i]["is_hang"] == 1){
            this.dialogShow13 = true
            this.is_hang_dialog_type = "打印发货单" 
            return false
          }
        }

        if (parseInt(this.selectRowLength) == 0) {
          // this.$message.error("请先选择要打印的订单");
          this.dialog2Show = true
          this.dialogText = '请勾选打印对象'
        } else {
          let checkedType = []
          this.resultList.data.forEach((value) => {
            if(value.checkedGoods) {
              checkedType.push(Object.keys(value.checkedGoods).length)
              if(Object.keys(value.checkedGoods).length<1) {
                this.nameVlaue = value.buyer_login_id
              }
            }else {
              checkedType.push(0)
            }
            
          })
          // if(checkedType.indexOf(0) !== -1) {
          //   this.dialogShow8 = true
          // }else {
            let arr = []
            this.orderInfoList.forEach((value)=> {
              if(value.invoice_print_state=='1') {
                arr.push(1)
              }
            })
            if(arr.length > 0) {
              this.dialogShow10 = true
            }else {
               this.$store.commit("batchprint/SAVE", {dialogFahuoConsignerVisible:true});
            }
            
          }
        // }
      } catch (error) {
        this.print_component_error = true
      }
    },
    
    // 点击打印快递单按钮
    handleClickExpressPrint() {
      if(window.print_component_error || !window.ks_print_list){
        this.print_component_error = true
        return false;
      }
      // 挂起订单提示
      for(let i in this.orderInfoList){
        if(this.orderInfoList[i]["is_hang"] == 1){
          this.dialogShow13 = true
          this.is_hang_dialog_type = "打印快递单"
          return false
        }
      }
      if (parseInt(this.selectRowLength) == 0) {
        // this.$message.error("请先选择要打印的订单");
        this.dialog2Show = true
        this.dialogText = '请勾选打印对象'
      } else {
        // let checkedType = []
        // this.resultList.data.forEach((value) => {
        //   if(value.checkedGoods) {
        //     checkedType.push(Object.keys(value.checkedGoods).length)
        //     if(Object.keys(value.checkedGoods).length<1) {
        //       this.nameVlaue = value.buyer_login_id
        //     }
        //   }else {
        //     checkedType.push(0)
        //   }
        // })
        
        // 标记是否有已打印订单
        this.has_express_print_state = false
        this.orderInfoList.map(item=>{
          if(item.express_print_state == 1 ){
            this.has_express_print_state = true;
          }
        })
        
        // if(checkedType.indexOf(0) !== -1) {
        //   this.dialogShow8 = true
        // }else {
        //   }
        
        this.$store.commit("batchprint/SAVE", {dialogExpressConsignerVisible:true});
      }
    },
    // 发货
    handleClickDeliverGoods() {
      for(let i in this.orderInfoList){
        if(this.orderInfoList[i]["is_hang"] == 1){
          this.dialogShow13 = true
          this.is_hang_dialog_type = "发货"
          return false
        }
      }
      if (parseInt(this.selectRowLength) == 0) {
        // this.$message.error("请先选择要打印的订单");
        this.dialog2Show = true
        this.dialogText = '请勾选发货对象'
        return false
      }
      this.$store.dispatch("batchprint/OpDeliverySendOrder",{data:this.orderInfoList}).then(res=>{
        if(res.code!=0){
          this.$message.error(res.message)
        }else{
          if(res.message != "成功"){
            this.GetFahuoError = res.message
          }else{
            this.$message.success("发货成功")
          }
          this.$store.dispatch("batchprint/get_search_result")
        }
      })
    },

    // 换页函数
    handleCurrentChange(page) {
      this.$store.commit("batchprint/SET_PAGENUM", page);
      this.$store.dispatch("batchprint/get_search_result");
      for (var i = 0; i < 20; i++) {
        this.addressEdit[i] = false;
      }
      this.selectRow = [];
      this.expandRow = [];
    },
    // 数据加密切换
    async showInfo(showAll,index){
      this.showAll=!showAll;
      let data={}
      data['orderId']=this.resultList.data[index].transaction_id;
      data['caid']=this.resultList.data[index].caid;
      if(this.showAll){
        // await this.$store.dispatch('singleprint/decryptBuyer',data).then((res)=>{});
      } 
    },
    offShow(){
      if(this.showAll){
        this.showAll=false;
        this.decryptInfo.contactPerson='';
        this.decryptInfo.mobile='';
        this.decryptInfo.address='';
        this.decryptInfo.city='';
        this.decryptInfo.province='';
        this.decryptInfo.town='';
      }
    },
    handleSizeChange(value) {
      // console.log(value+'--------page----');
      this.pageDropdown = value+'条/页'
      this.$store.commit("batchprint/SET_PAGENUM", 1);
      this.$store.commit("batchprint/SET_LISTLIMIT", value);
      this.$store.dispatch("batchprint/get_search_result");
      for (var i = 0; i < 20; i++) {
        this.addressEdit[i] = false;
      }
      this.selectRow = [];
      this.expandRow = [];
    },
    handleCheckedGoodsChange(index,value) {
    },
    comfirBtn() {
      window.location.href = '/打印控件.zip'
    },
    refreshBtn() {
      location.reload();
    },
    reduceClick() {
      this.$parent.ExpressGlobalSettings();
    },
    addClick() {
      this.$parent.openAddExpressVisible();
    },
    infoBtn() {
      // this.searchFormData.startTime = [this.getBeginTime(), this.getEndTime()];
      this.searchFormData.Time = '1';
      this.searchFormData.status = '';
      this.searchFormData.print_state = '';
      this.searchFormData.ww_baby_count_money = '';
      this.searchFormData.delivery_limit_type= '';
      this.searchFormData.refund_state= '';
      this.searchFormData.remark_type = ''; 
      this.$store.commit("batchprint/SET_PAGENUM", 1);
      this.$store.commit("batchprint/SAVE",{is_shipped:1})
      this.$store.dispatch("batchprint/get_search_result")
      this.$store.commit("batchprint/SET_ISSHOW", "ResultList");
      this.$store.commit("batchprint/SET_DATATYPE", false);
    },    
   
 
    // 更新挂起状态
    UpdateHangFN( status ){
      let params = {
        is_hang: status,
        create_time: [],
        transaction_id: [],
      }
      this.orderInfoList.map((item)=>{
        params.create_time.push(item.create_time)
        params.transaction_id.push(item.transaction_id)
      })
      this.$store.dispatch("batchprint/UpdateHang", params).then(res=>{
        if(res.code==0){
          this.dialogText2 = status==1?'所选订单挂起成功！':'所选订单取消挂起成功！'
          this.dialogShow = true
          setTimeout(()=> {
            this.dialogShow = false
          }, 3000)
        }
      });
    },
    chenckItemHandle(command) {
      if(command == '挂起') {
        if (parseInt(this.selectRowLength) == 0) {
          this.dialog2Show = true
          this.dialogText = '请勾选挂起对象'
        }else {
          this.UpdateHangFN(1)
        }
      }else if(command == '取消挂起') {
        if (parseInt(this.selectRowLength) == 0) {
          this.dialog2Show = true
          this.dialogText = '请勾选取消挂起对象'
        }else {
          this.UpdateHangFN(2)
        }
      }
    },

   
    showFunction(value) {
        for(let i in this.tableHeader) {
          if(this.tableHeader[i].lable == value) {
            return this.tableHeader[i].show
          }
        }
      },
      saveRemarkValue() {
      if (this.flagIndex == '-1') {
        this.$message.error('请选择旗子')
      } else {
        this.memoParams.memo = this.NaNpxarksTextarea,
          this.memoParams.remarkIcon = this.flagIndex,
          this.$store
            .dispatch("singleprint/OrderMemoAdd", this.memoParams)
            .then((res) => {
              this.dialogShow12 = false
              this.dialogText2 = '所选订单批量备注成功！'
              this.$store.dispatch("batchprint/get_search_result");
              this.$store.commit("batchprint/SET_ISSHOW", "ResultList");
              this.$store.commit("batchprint/SET_DATATYPE", false);
              this.dialogShow = true
              setTimeout(() => {
                this.dialogShow = false
              }, 3000)
            });
      }



    }, showFunction(value) {
      for (let i in this.tableHeader) {
        if (this.tableHeader[i].lable == value) {
          return this.tableHeader[i].show
        }
      }
    },
    visibleChange(value) {
      this.visibleChangeValue = value
    },
     changeFlag(value) {
      this.flagImgShow = false
      // if(value == '0') this.resultList.data[index].flagValue = ashFlag
      this.falgRadio_temp = value;
      setTimeout(() => {
        this.flagImgShow = true
      }, 0)
    },
    saveMemo(list, index) {
      if (this.falgRadio_temp) {
        let params = {
          orderId: [list.transaction_id],
          remarkIcon: this.falgRadio_temp,
          memo: this.seller_memo_value,
          create_time: [list.create_time]
        }
        this.$store
          .dispatch("singleprint/OrderMemoAdd", params)
          .then((res) => {
            this.$message.success(res.message);
            if (this.falgRadio_temp == '1') this.resultList.data[index].flagValue = redFlag
            if (this.falgRadio_temp == '2') this.resultList.data[index].flagValue = blueFlag
            if (this.falgRadio_temp == '3') this.resultList.data[index].flagValue = greenFlag
            if (this.falgRadio_temp == '4') this.resultList.data[index].flagValue = yellowFlag
            this.falgRadio_temp = ""
            this.resultList.data[index].seller_memo = this.seller_memo_value
            this.flagShow = false
          });
      } else {
        this.$message.error("请选择旗子");
      }
    },
    changeMemo(seller_memo) {
      this.flagShow = true
      this.seller_memo_value = seller_memo
    },
  },
  updated() {
    if(this.searchFormData.status==0){
      this.selectMsg='全部'
    }
    if(this.searchFormData.status==1){
      this.selectMsg='待扣款'
    }
    if(this.searchFormData.status==2){
      this.selectMsg='待发货'
    }
    if(this.searchFormData.status==3){
      this.selectMsg='已发货'
    }
    if(this.searchFormData.status==4){
      this.selectMsg='已取消'
    }
    this.$set(this.addressEdit, this.showAdress, false);
  },
  
};
</script>

<style lang="less" scoped>
/deep/ .selected-bgcolor {
  td {
    background-color: #99d699 !important;
  }
}
/deep/ .expanded-bgcolor {
  td {
    border: none !important;
    background-color: #ceb40c !important;
  }
}
/deep/ .lockClass {
  td {
    border: none !important;
    background-color: #b0c1d2 !important;
  }
}
.result-container {
  width: 100%;
  height: 100%;
}
.info-table {
  .table-row {
    display: block;
    margin: 10px 0;
  }
  .table-key {
    display: inline-block;
    margin-right: 10px;
    color: #507cd3;
  }
  .table-value {
    display: inline-block;
    color: #c4acad;
  }
}
/deep/ .el-radio__label {
  font-size: 12px;
  padding-left: 5px;
}
/deep/ .el-radio {
  margin-right: 10px;
}
.help-tip {
  background-color: #fff;
  color: #2c2b3b!important;
}
.result-table {
  width: 95%;
  margin: 10px auto;
  NaNpxark-add-btn {
    background-color: #fff;
    background-image: none;
    border: 1px solid #8ec9f6;
    color: #8ec9f6;
    padding: 5px 6px;
    position: absolute;
    right: 0;
  }
  .product-info {
    display: flex;
    flex-direction: row;
    margin: 5px;
    margin-left: 0;
  }
  /deep/ .el-form-item__content {
    line-height: 0;
  }
}
/deep/ .el-table {
  font-size: 12px;
}
/deep/ .el-table .cell {
  display: flex;
  align-items: center;
  line-height: initial;
  padding-right: 0;
}
/deep/ .el-table th > .cell {
  color: #000;
  font-weight: normal;
}
/deep/ .el-table__header {
  height: 30px;
  border: 1.5008px solid #c4d2db;
}
/deep/ .el-table th {
  padding: 5px 0;
}
/deep/ .el-table th {
  background-color: #e2f2f3;
}
/deep/ .el-table td {
  border-bottom: 1px solid #cdd7df;
  padding: 20px 0px;
}
/deep/ .el-table th.is-leaf {
  border: none;
}
/deep/ .el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #cdd7df;
}
/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #f5fafa;
}
// /deep/  .el-icon-arrow-right:before{
//   font-family: "iconfont" !important;
//   content: "\e715";
//   font-size: 14px;
// }
// /deep/ .el-table__expand-icon--expanded{
//   transform: rotate(180deg);
// }
/deep/ .el-table__expanded-cell[class*="cell"] {
  padding: 0;
}
/deep/ .el-table__expand-icon>.el-icon {
  margin-top: -6px;
  font-size: 16px;
}
/deep/ .iconfont {
  font-size: 14px;
}
/deep/ .el-textarea__inner {
  padding: 5px;
  font-size: 12px;
  color: #7a7a7a;
  border: 1px solid #7a7a7a;
  border-radius: 2px;
}
.expend-container {
  width: 100%;
  height: 100%;
  padding: 15px;
  border-right: 10px solid #ceb40c;
  border-left: 10px solid #ceb40c;
  border-bottom: 10px solid #ceb40c;

  .row2 {
    padding: 5px 10px;
    margin: 5px 0;
    background-color: #e9d18e;
  }

  .row3 {
    .row3-img {
      width: 60px;
      height: 60px;
      margin: 0 10px 0 0;
    }
  }

  .row4 {
    width: 400px;

    .row4-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px;
    }
  }
}
.expend-table {
  td {
    border: none;
    padding: 5px 0;
  }
  tr:hover > td {
    background-color: #fff !important;
  }
  .table-key {
    display: inline-block;
    width: 60px;
    text-align: left;
    font-weight: 1000;
  }
  .table-value {
    display: inline-block;
    margin-right: 30px;
  }
  .table-menu {
    display: inline-block;
  }
  /deep/ .el-select {
    width: 120px;
  }
}
.pagination {
  display: flex;
  justify-content: center;
}
.footer-container {
  width: calc(100% - 60px);
  margin: auto;
  position: fixed;
  bottom: 0;
  background: rgba(0,0,0,0.7);
  z-index: 2;
  .icon-box{
    position: absolute;
    left: 2.5vw;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    .footer-btn {
      background-image: none;
      margin: 5px;
      font-weight: 1000;
      color: #fff;
      width: 120px;
    }
    .btn1 {
      background-color: #23b7e5;
    }
    .btn2 {
      background-color: #02b77e;
    }
    .btn3 {
      background-color: #f5841f;
    }
    .btn4 {
      background-color: #999999;
    }
  }
}
/deep/ .el-divider--horizontal {
  height: 1px;
}
.choose-template-dialog {
  height: 200px;
  position: relative;
  .close-btn {
    position: absolute;
    top: -10px;
    right: 0px;
    cursor: pointer;
  }
  .header {
    margin: 10px 20px;
    font-size: 12px;
    font-weight: 1000;
  }
  .content {
    margin: 20px;
    margin-top: 30px;
  }
}
  .dropdownClass {
    height: 28px;
    border: 1px solid #ccc;
    line-height: 28px;
    padding: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  .titleClass {
    font-size: 12px;
    line-height: 12px;
    background-color: red;
    color: #fff;
    padding: 0 3px;
    display: inline-block;
  }
  .messageClass {
    width: 97.3%;
    height: 30px;
    border: 1px solid #ff0000;
    background-color: #ffffcd;
    line-height: 30px;
    color: #365064;
    margin-top: 15px;
    div {
      margin-left: 10px;
    }
    span {
      color: #ff0000;
      font-weight: 600;
    }
    p {
      color: #387be2;
      display: inline-block;
      cursor: pointer;
    }
  }
  .contentClass {
    height: 170px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      color: #00bb9c;
      font-size: 50px;
      line-height: 50px;
    }
    span {
      font-size: 20px;
      line-height: 20px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
  .tipsContentClass {
    height: 150px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    i {
      color: #f6891e;
      font-size: 42px;
      line-height: 50px;
    }
    span {
      font-size: 15px;
      line-height: 22px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
  .contentClass2 {
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    color: #ff7614;
    font-size: 22px;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      height: 117px;
    }
    i {
      color: #ff7614;
      font-size: 40px;
      // line-height: 110px;
      margin-left: 25px;
    }
    .contentFooter {
      height: 53px;
      width: 100%;
      background-color: #f3f3f3;
      border-top: 1px solid #eaeaea;
      text-align: right;
      p {
        font-size: 15px;
        line-height: 15px;
        background-color: #ff7614;
        color: #fff;
        display: inline-block;
        padding: 9px 13px;
        border-radius: 4px;
        margin: 10px;
        cursor: pointer;
      }
    }
  }
  .contentClass3 {
    color: #000;
    padding: 15px;
    .refreshBtn {
      font-size:14px;
      line-height:14px;
      color:#3366cc;
      margin:0;
      text-decoration:underline;
      cursor: pointer;
    }
    i {
      font-size: 26px;
      line-height: 26px;
      color: #ff7614;
    }
    span {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: #ff7614;
      margin-left: 10px;
    }
    div:nth-child(1) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        margin-left: 36px;
      }
    }
    .contentFooter {
      width: 100%;
      text-align: right;
      margin-top: 40px;
      div {
        background-color: #ff7614;
        padding: 5px 18px;
        display: inline-block;
        color: #fff;
        border-radius: 3px;
        cursor: pointer;
      }
      div:nth-child(2) {
        background-color: #fff;
        border: 1px  solid #b4b4b4;
        color: #333;
        margin-left: 10px;
      }
    }
  }
  .contentClass4 {
    color: #000;
    padding-top: 20px;
    i {
      font-size: 26px;
      line-height: 26px;
      color: #ff7614;
    }
    span {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: #ff7614;
      margin-left: 10px;
    }
    div:nth-child(1) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 20px;
      padding-right: 15px;
      p {
        margin-left: 36px;
      }
    }
    .contentFooter {
      height: 53px;
      background-color: #f3f3f3;
      border-top: 1px solid #eaeaea;
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: right;
      font-size: 14px;
      line-height: 25px;
      div {
        background-color: #ff7614;
        padding: 5px 18px;
        display: inline-block;
        color: #fff;
        border-radius: 3px;
        cursor: pointer;
      }
      div:nth-child(2) {
        background-color: #fff;
        border: 1px  solid #b4b4b4;
        color: #333;
        margin-left: 10px;
        margin-right: 15px;
      }
    }
  }

  .contentClass6 {
    color: #000;
    padding-top: 20px;
    i {
      font-size: 26px;
      line-height: 26px;
      color: #ff7614;
    }
    span {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: #ff7614;
      margin-left: 10px;
    }
    div:nth-child(1) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 20px;
      padding-right: 15px;
      p {
        margin-left: 36px;
      }
    }
    .contentFooter {
      height: 53px;
      background-color: #f3f3f3;
      border-top: 1px solid #eaeaea;
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: right;
      font-size: 14px;
      line-height: 25px;
      div {
        background-color: #ff7614;
        padding: 5px 18px;
        display: inline-block;
        color: #fff;
        border-radius: 3px;
        cursor: pointer;
      }
      div:nth-child(2) {
        background-color: #fff;
        border: 1px  solid #b4b4b4;
        color: #333;
        margin-left: 10px;
        margin-right: 15px;
      }
    }
    .textBtn {
      margin:0;
      color:#3366cc;
      font-size:14px;
      text-decoration:underline;
      cursor: pointer;
    }
  }
  .contentClass7 {
    color: #000;
    i {
      font-size: 40px;
      line-height: 126px;
      color: #ff7614;
    }
    span {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: #333;
      margin-left: 10px;
    }
    div:nth-child(1) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 40px;
      padding-right: 15px;
      p {
        margin-left: 36px;
      }
    }
    .textBtn {
      margin:0;
      color:#3366cc;
      font-size:14px;
      text-decoration:underline;
      cursor: pointer;
    }
  }
  .contentClass8 {
    color: #000;
    padding-top: 20px;
    i {
      font-size: 26px;
      line-height: 26px;
      color: #ff7614;
    }
    span {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: #ff7614;
      margin-left: 10px;
    }
    div:nth-child(1) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 20px;
      padding-right: 15px;
      p {
        margin-left: 36px;
      }
      img {
        margin: 5px 0 0 36px;
      }
    }
    .contentFooter {
      height: 53px;
      background-color: #f3f3f3;
      border-top: 1px solid #eaeaea;
      width: 100%;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: right;
      font-size: 14px;
      line-height: 25px;
      div {
        background-color: #ff7614;
        padding: 5px 18px;
        display: inline-block;
        color: #fff;
        border-radius: 3px;
        cursor: pointer;
        margin-right: 15px;
      }
    } 
    .textBtn {
      margin:0;
      color:#3366cc;
      font-size:14px;
      text-decoration:underline;
      cursor: pointer;
    }
  }
  .contentClass12 {
    .topBg {
      width: 100%;
      height: 10px;
      background-color: #f6861e;
    }

    .topTitle {
      padding: 0 10px;
      height: 30px;
      display: flex;
      align-items: flex-end;
      padding-bottom: 10px;
      border-bottom: 2px solid #e6e6e6;
    }

    .topTitle span:nth-child(1) {
      font-size: 18px;
      color: #365064;
      font-weight: 600;
      margin-right: 10px;
    }

    .footerClass {
      display: flex;
      justify-content: right;
      padding: 10px 0;
      background-color: #f3f3f3;

      div {
        padding: 6px 15px;
        background-color: #ff7614;
        margin-right: 15px;
        border-radius: 3px;
        color: #fff;
        cursor: pointer;
      }

      div:nth-child(2) {
        background-color: #fff;
        border: 1px solid #b4b4b4;
        color: #333;
      }
    }

    .flagClass {
      display: flex;
      justify-content: space-between;
      padding: 0 15px;
      margin: 0;
      margin-bottom: 15px;

      div {
        border: 1px solid #ccc;
        padding: 5px 20px;
        border-radius: 25px;
        cursor: pointer;

        img {
          margin-right: 7px;
        }
      }

      .flageActive {
        border: 1px solid #f5831f;
      }
    }
  }
  .flagClass {
    margin-bottom: -3px;
    margin-right: 10px;
  }
  
  /deep/ .el-dialog {
    background-color: #fff;
  }
  /deep/ .el-dialog__header {
      padding: 0;
  }
  /deep/ .el-dialog__body {
      padding: 0;
  }
  /deep/ .el-dialog__close{
      font-size: 20px;
      font-weight: 600;
      color: #2c2b3b;
  }
  /deep/ .el-dialog__close:hover {
      color: #2c2b3b;
  }
  .handlBtn {
    display: inline-block;
    background-color: #ffe1a1;
    color: #fab15b;
    font-size: 14px;
    line-height: 12px;
    margin: 0;
    padding: 2px;
    cursor: pointer;
  }
  
  /deep/ .table-value .el-input__inner {
    border: none;
    color: #ff0000;
    font-weight: 600;
    padding: 0px;
    border-bottom: 1px solid #8dacc3;
    
  }
  /deep/ .table-value .el-input {
    width: 800px;
  }
  .el-icon-lock {
    color: #333;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
  }
.flagDiv {
  display: inline-block;
  position: absolute;
  top: 65px;
  left: 15PX;
  border: 1px solid #aaa;
  padding: 10px 20px;

  .el-radio {
    margin: 0;
  }

  .handleBtn {
    margin-left: 10px;
    font-size: 12px;
    line-height: 12px;
  }
}
</style>
